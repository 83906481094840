import {Grid} from "@mui/material";
import * as React from "react";

export const MeetingType = ({colorPreset, TypeOfEvent, DateTime, updateField, isPreview, isEditor}) => {
    return (
        <React.Fragment>
            <Grid container sx={{backgroundColor: `${colorPreset?.Header?.EventType?.backgroundColor??'rgba(60, 61, 63, 0.6);'}`, pl: '20px'}} className="TypeOfEvent">
                <Grid onClick={() => updateField('TypeOfEvent')} item xs={12} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset',pt: '15px', pb: '15px', color: '#FFFFFF', fontSize: '18px', lineHeight: '22px', fontWeight: 700}} dangerouslySetInnerHTML={{__html: TypeOfEvent}} />
            </Grid>
            <Grid container  sx={{ backgroundColor: `${colorPreset?.Header?.EventLocation?.backgroundColor??'#00A6DD'}`, pl: '20px'}} className="DateTime">
                <Grid onClick={() => updateField('DateTime')} item xs={12} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset',pt: '15px', pb: '15px', color: '#FFFFFF', fontSize: '18px', lineHeight: '22px', fontWeight: 400}} dangerouslySetInnerHTML={{__html: DateTime}}/>
            </Grid>
        </React.Fragment>
        )

}