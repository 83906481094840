import {Grid} from "@mui/material";
import * as React from 'react';
export const Details = ({content, updateField, isPreview, isEditor}: {content: string, updateField: any, isPreview: boolean, isEditor: boolean}) => {

    return (
        <React.Fragment>
            <Grid className="Details" container  sx={{ pt: '20px', pb: '20px', width: '100%',  backgroundColor: 'rgba(60, 61, 63, 0.08)'}}>
                <Grid
                    onClick={updateField}
                    item xs={12}
                    sx={
                        {
                            border: !content && isPreview && isEditor ? '1px dotted #808080' : 'none',
                            cursor: isPreview && isEditor ? 'pointer': 'unset',
                            pt: '15px',
                            pb: '15px',
                            color: '#3C3D3F',
                            fontSize: '18px',
                            fontWeight: 400
                        }}
                    dangerouslySetInnerHTML={{__html: !content && isPreview && isEditor ? 'Details here...' : content}} />
            </Grid>
        </React.Fragment>
    )
}
