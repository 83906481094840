import {Backdrop, CircularProgress, Paper} from "@mui/material";
import {Header} from "./Header";
import * as React from "react";
import {useEffect} from "react";
import axios from "axios";
import {sendErrorMessage} from "../../Error";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Body} from "./Body";
import {Footer} from "./Footer";

export const PrintInvitation = ({isApproval}: {isApproval: boolean}) => {
    const params = useParams();
    // @ts-ignore
    const uuid = React.useRef(params.uuid)
    const [content, setContent] = React.useState<TypePrintContent | null>();
    const [isPreview, setIsPreview] = React.useState(false);
    const [isEditor, setIsEditor] = React.useState(false);
    const [colorPreset, setColorPreset] = React.useState(undefined);
    const [smallLogoImages, setSmallLogoImages] = React.useState(undefined);
    const [jointEvent, setJointEvent] = React.useState<boolean>(false);
    const [footer, setFooter] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [displayAgenda, setDisplayAgenda] = React.useState(true);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [activityId, setActivityId] = React.useState(uuid.current);
    const updateField = (fieldName: string) => {
        if (isPreview && isEditor) {
            let fieldType = fieldName === 'Button' ? 'Text' : 'HTML';
            console.log({type: 'updateTemplateField', fieldName, fieldType });
            window.parent.postMessage({type: 'updateTemplateField', fieldName, fieldType }, "*");
        }
    }
    useEffect( () => {
        if ( uuid.current !== "PREVIEW" ) {
            (async() => {
                await getPrintVersion();
            })();
        } else {
            window.addEventListener("message", (event) => {
                let tmpContent: TypePrintContent = {};
                if ( event.data.type === "updatePreview" ) {
                    setIsDisabled(!event.data?.enabled);
                    setActivityId(event.data?.activityId);
                    if (!event.data?.enabled){
                        (async() => {
                            uuid.current = event.data?.activityId;
                            await getPrintVersion();
                        })()
                        return;
                    }
                    setSmallLogoImages(event.data?.smallLogoImages);
                    setColorPreset(event.data?.colorPreset)
                    setIsPreview(true);
                    setIsEditor(event.data.isEditor)
                    setJointEvent(event.data?.jointEvent);
                    setFooter(event.data?.Footer)
                    console.log({...event.data});
                    setDisplayAgenda(event.data?.template?.displayAgenda);
                    for (let field of event.data.fields) tmpContent = {...tmpContent, [field.name]: field.value};

                    setContent(
                        {
                            ...tmpContent,
                            country: event.data.country,
                            Speakers: event.data.Speakers,
                        });
                    setLoading(false);
                }
            }, false);
        }
    }, [])


    const getPrintVersion = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_PRINT_API}invitation/${uuid.current}`);
            let tmpContent: TypePrintContent = {};
            for (let field of res.data.template.fields) tmpContent = {...tmpContent, [field.name]: field.value};

            tmpContent = {
                ...tmpContent,
                country: res.data.country,
                Speakers: res.data.Speakers,
                isOpen: !isApproval ? res.data.isOpen : isApproval,
            }
            console.log(res.data)
            setDisplayAgenda(res.data?.template?.displayAgenda);
            setColorPreset(res.data?.colorPreset)
            setJointEvent(res.data?.jointEvent);
            setSmallLogoImages(res.data?.smallLogoImages);
            setLoading(false);
            setFooter(res.data?.Footer)
            setContent(tmpContent);
            setIsPreview(false);
        } catch ( e: any ) {
            setLoading(false);
            await sendErrorMessage(e,  'public/print/getPrint', "Cannot Get Print");
        }
    }
    return (
        <Box display="flex" alignItems="center" justifyContent="center" >
            <Paper
                className={'PrintPaper'}
                sx={{
                    maxWidth: 'calc(210mm - 60px)',
                    maxHeight: 'calc(297mm - 60px)',
                    minWidth: 'calc(210mm - 60px)',
                    minHeight: 'calc(297mm - 60px)',
                    padding: '40px',
                    fontSize: '10pt',
                }}
            >
                <Header
                    Header={content?.Header}
                    SubHeader={content?.SubHeader}
                    logoImage={content?.__LogoImage}
                    isEditor={isEditor}
                    isPreview={isPreview}
                    colorPreset={colorPreset}
                    smallLogoImages={smallLogoImages}
                    updateField={updateField}
                    jointEvent={jointEvent}
                />
                {
                    content && <Body
                        content={content}
                        colorPreset={colorPreset}
                        isEditor={isEditor}
                        isPreview={isPreview}
                        uuid={activityId}
                        updateField={updateField}
                        displayAgenda={displayAgenda}
                    />
                }
                <Footer html={footer} />
                {
                    isDisabled && <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Halvtransparent svart för en gråaktig ton
                    }} />
                }
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}