import {Box, Grid} from "@mui/material";
import * as React from 'react';
export const Header = ({Header, SubHeader, logoImage, TypeOfEvent, DateTime, isEditor, isPreview, updateField, smallLogo, colorPreset, smallLogoImages, jointEvent}) => {
    const [screenWidth] = React.useState(window.innerWidth)
    const [isMobile] = React.useState(window.innerWidth < 500)
    const [showRightLogo, setShowRightLogo] = React.useState((smallLogoImages?.length??0) < 3 && !jointEvent)
    React.useEffect( () => {
        setShowRightLogo((smallLogoImages?.length??0) < 3 && !jointEvent);
    }, [smallLogoImages])
    return (
        <React.Fragment>
            <Grid container  className="Header">
                <Grid item xs={screenWidth >= 500 && showRightLogo ? 7: 12} className="HeaderLeftColumn">
                    {
                        smallLogoImages?.length ?
                            smallLogoImages.length === 1 ?
                                <Grid paddingTop={'10px'} paddingRight={isMobile ? '20px' : '40px'}>
                                    <img style={{width: !jointEvent ? 'unset' : '100%'}}  src={`${process.env.REACT_APP_UPLOAD_BUCKET}${smallLogoImages[0].image}`}/>
                                </Grid>
                            : <Grid container width={'100%'}>
                                {
                                    smallLogoImages.map( (image: any, index: number) => {
                                        return (
                                        <Grid xs={smallLogoImages.length >2 ? 4 : 6} direction="column" sx={{display: 'grid'}}
                                              alignItems="center" >
                                            <Box
                                            display="flex"
                                            justifyContent={index === 3 ? 'left' : 'center'}
                                            alignItems="center"
                                            height="100%"
                                            padding={'10px'}
                                            >
                                            <img style={{width: '100%'}} className="SmallLogoImage" src={`${process.env.REACT_APP_UPLOAD_BUCKET}${image.image}`}/>
                                        </Box>
                                        </Grid>)
                                    })
                                }
                            </Grid>
                            :
                            <Grid className="SmallLogo">
                                <img className="SmallLogoImage" style={{paddingTop: '20px', width: '145px'}} src={smallLogo}/>
                            </Grid>
                    }

                    <Grid sx={{maxWidth: isMobile || colorPreset?.Header?.Image?.Desktop?.src ? '310px': 'unset'}}>
                        <Box onClick={() => updateField('Header')} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset', color:`${colorPreset?.Header?.Subject?.color??'#D3031C'}!important`, fontSize: '51px', lineHeight: '67px', pt: '10px'}} dangerouslySetInnerHTML={{__html: Header}} />
                    </Grid>
                    <Grid sx={{maxWidth: isMobile || colorPreset?.Header?.Image?.Desktop?.src ? '310px': 'unset'}}>
                        <Box sx={{ fontSize: '19px', lineHeight: '24px', pt: '10px', color:`${colorPreset?.Header?.SubSubject?.color??'#3C3D3F'}!important`}} dangerouslySetInnerHTML={{__html: SubHeader}}/>
                    </Grid>

                </Grid>
                {
                    screenWidth >= 500 && showRightLogo &&
                    <Grid item xs={5} sx={{textAlign: 'right'}} alignContent="right">
                        <img className="LogoImage"  src={colorPreset?.Header?.Image?.Desktop?.src??logoImage}/>
                    </Grid>
                }

            </Grid>
            <Grid container sx={{mt: `${screenWidth < 500 ? '20px' : colorPreset?.Header?.EventType?.paddingTop??'20px'}`, backgroundColor: `${colorPreset?.Header?.EventType?.backgroundColor??'rgba(60, 61, 63, 0.6);'}`}} className="TypeOfEvent">
                <Grid item xs={12} sx={{pt: '15px', pb: '15px', color: '#FFFFFF', fontSize: '18px', lineHeight: '22px', fontWeight: 700}} dangerouslySetInnerHTML={{__html: TypeOfEvent}} />
            </Grid>
            <Grid container  sx={{ backgroundColor: `${colorPreset?.Header?.EventLocation?.backgroundColor??'#00A6DD'}`}} className="DateTime">
                <Grid item xs={12} sx={{pt: '25px', pb: '25px', color: '#FFFFFF', fontSize: '18px', lineHeight: '22px', fontWeight: 400}} dangerouslySetInnerHTML={{__html: DateTime}}/>
            </Grid>
        </React.Fragment>
    )
}
