import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Link} from "@mui/material";
import PropTypes from "prop-types";
import {getTranslation} from "../getTranslation";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, pt: 2,pb:0, marginBottom: '0px!important' }} {...other}>
            <Typography component="div" variant="h6" align="left" sx={{mb: 2}}>{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};


export const MoreInfo = ({country, handleFieldChange}) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        handleFieldChange();
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Link href="#" onClick={handleClickOpen} sx={{fontSize: '14px'}}>
                {getTranslation('Consent Form', 'more_info')}
            </Link>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {getTranslation('Consent Form', 'label_why_consent')}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {getTranslation('Consent Form', 'more_info_why_consent')}
                    </Typography>
                </DialogContent>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {getTranslation('Consent Form', 'label_channels')}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {getTranslation('Consent Form', 'more_info_channels')}
                    </Typography>
                </DialogContent>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {getTranslation('Consent Form', 'label_optout')}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {getTranslation('Consent Form', 'more_info_optout')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        {getTranslation('Consent Form', 'label_close')}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
MoreInfo.propTypes = {
    country: PropTypes.string.isRequired,
    handleFieldChange: PropTypes.func.isRequired
}
