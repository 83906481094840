import axios from "axios";
const {REACT_APP_ERROR_API} =  process.env;

export const sendErrorMessage = async(error: any, script: string, friendlyErrorMessage: string) => {
    let errorMessage = error.message
    if ( error.response ) {
        errorMessage = `${errorMessage}, [${JSON.stringify(error.response.data)}]`
    }
    console.error(errorMessage, error.stack, script);
    try {
        await axios.put(`${REACT_APP_ERROR_API}`, {errorMessage, errorStack: error.stack, script});
    } catch ( e: any ) {
        if ( e.response ) {
            console.log(e);
            console.error(e.response.data, e.response);
        } else {
            console.error(e.toString(), e);
        }
    }

}
