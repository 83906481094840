import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export const Speakers = ({content}: {content: TypePrintContent}) => {
    const numSpeakers = content['Speakers']?.length??0;
    return (
        <Grid container>
            {
                content['Speakers'] && content['Speakers'].map((speaker: any, index) => {
                    return (
                        <Grid item xs={numSpeakers > 2 ? 4 : 6} key={index} sx={{paddingTop: '10px', paddingBottom: '10px', textAlign: 'center'}}>

                            <img onError={(err) => {
                                // @ts-ignore
                                err.target.style.visibility = 'hidden';
                            }} style={{minHeight: '70px', maxHeight: '70px'}} src={`${speaker.image}`}/>
                            <Typography sx={{fontSize: '9pt', lineHeight: '15px', fontWeight: 700}}>{speaker.fullname}</Typography>
                            <Typography sx={{fontSize: '9pt', lineHeight: '15px', fontWeight: 400}}>{speaker.company}</Typography>
                            <Typography sx={{fontSize: '9pt', lineHeight: '15px', fontWeight: 400}}>{speaker.title}</Typography>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}