import {Grid} from "@mui/material";
import * as React from 'react';
export const Footer = ({html}) => {

    return (
            <Grid container  sx={{  backgroundColor: '#FFFFFF', fontSize: '7pt', borderTop: '1px solid gray', mt: '15px'}}>
               <Grid item xs={12} sx={{pt: '5px',  color: '#3C3D3F', fontWeight: 400}} dangerouslySetInnerHTML={{__html: html}}/>
            </Grid>
    )
}
