import {Box, FormControl, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import * as React from 'react';
import Button from "@mui/material/Button";

import {Question} from "./Question";
import EditIcon from "@mui/icons-material/Edit";

interface FormType {
    content: TypeSurveyContent,
    handleSubmit: any,
    isPreview: boolean,
    isEditor: boolean,
    colorPreset: any,
    updateField: any
}
export const Form = ({content, handleSubmit, colorPreset, isPreview, isEditor, updateField}: FormType) => {

    return (
        <Box id="surveyForm" className="SurveyForm" component="form" onSubmit={handleSubmit} noValidate>
            <FormControl component="fieldset" sx={{width: '100%'}}>
                {
                    content.questions.map( (questionGroup, index) => {
                        if ( index === 0 || content.questions[index].category !== content.questions[index-1].category) {
                            return(
                                <Box sx={{textAlign: 'left', borderBottom: 0, paddingBottom: '20px', marginBottom: '20px'}}>
                                    <Typography component="h3" variant="h3" sx={{textAlign: 'left', paddingLeft: '0px'}}>
                                        {questionGroup.category}
                                    </Typography>
                                    {
                                        content.questions.map( (question, index) => {
                                            if ( question.category === questionGroup.category ) {
                                                return (<Question {...question} />);
                                            }

                                        })
                                    }
                                </Box>
                            )
                        }

                    })
                }
            </FormControl>
            <Grid sx={{textAlign: 'center', pb: '40px'}}>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2,  marginTop: '10px', backgroundColor: colorPreset?.Button?.backgroundColor??'#D51317', borderRadius: '30px',
                        fontSize: '15px', lineHeight: '18px', fontWeight: 700, pt: '15px', pb: '15px', pl: '40px', pr: '40px' }}
                >
                    {content.Button}
                </Button>
                {
                    isPreview  && isEditor &&

                    <Tooltip title="Edit">
                        <IconButton onClick={()=>updateField('Button')}>
                            <EditIcon sx={{fontSize: 20}}/>
                        </IconButton>
                    </Tooltip>

                }
            </Grid>

        </Box>
    )
}
