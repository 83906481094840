import React, {useEffect} from 'react';
import logo from './assets/logo.png'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {CssBaseline} from "@mui/material";

const theme = createTheme();

export const ErrorPage = () => {
    const [confirmationText, setConfirmationText] = React.useState({line1: '', line2: '', line3: ''});

    const oldLink = () => {
        setConfirmationText({line1: `This event is no longer active.`,
            line2: ``,
            line3: ''})
    }
    useEffect( () => {
        oldLink();
    }, [])
    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div style={{marginTop: '50%'}}>
                <img alt="Tillotts Logo" src={logo}/>
                <Typography component="h3" variant="h6">
                    {confirmationText.line1}
                    <br/>
                    <br/>
                    {confirmationText.line2}
                    <br/>
                    {confirmationText.line3}
                </Typography>
            </div>
        </Container>
        </ThemeProvider>
    );
}
