import {Backdrop} from "@mui/material"
import * as React from "react";
import Typography from "@mui/material/Typography";
import {getTranslation} from "../getTranslation";

interface WebFormNotOpenInput {
    type: 'Registration Form' | 'Survey Form';
    start: Date;
    end: Date;
    country: string;
    message?: string;
}
export const WebFormNotOpen = ({start, end, country, type, message}: WebFormNotOpenInput) => {
    const messages = {
        REGISTRATION: {
            early: {
                DK: 'Desværre ser det ud til, at du kommer lidt for tidligt. Registreringen er endnu ikke åben, men du er velkommen til at komme tilbage senere.',
                FI: 'Valitettavasti näyttää siltä, että saavut paikalle hieman liian aikaisin. Rekisteröinti ei ole vielä avoinna, mutta voit tulla uudelleen pian.',
                NO: 'Beklager, det ser ut til at du kommer litt for tidlig. Registreringen har ikke åpnet ennå, men du er velkommen til å komme tilbake senere.',
                SE: 'Tyvärr verkar det som att du kommer lite för tidigt. Registreringen har inte öppnat ännu, men du är välkommen att återkomma snart igen.'
            },
            late: {
                DK: 'Desværre er det ikke længere muligt at tilmelde sig dette event.',
                FI: 'Valitettavasti tämän tapahtuman ilmoittautuminen on jo päättynyt.',
                NO: 'Beklager, men det er ikke lenger mulig å registrere seg for dette eventet.',
                SE: 'Tyvärr är det inte längre möjligt att registrera sig till detta event.'
            }
        },
        SURVEY: {
            early: {
                DK: 'Desværre ser det ud til, at du kommer lidt for tidligt. Evalueringen er endnu ikke åben, men du er velkommen til at komme tilbage, så snart eventet er afsluttet.',
                FI: 'Valitettavasti näyttää siltä, että saavut paikalle hieman liian aikaisin. Arviointi ei ole vielä avoinna, mutta voit tulla uudelleen tapahtuman päätyttyä.',
                NO: 'Beklager, det ser ut til at du kommer litt for tidlig. Evalueringen er ikke åpen ennå, men du er velkommen til å komme tilbake så snart eventet er avsluttet.',
                SE: 'Tyvärr verkar det som att du kommer lite för tidigt. Utvärderingen har inte öppnat ännu, men du är välkommen att återkomma så snart eventet har avslutats.'
            },
            late: {
                DK: 'Desværre er det ikke længere muligt at evaluere dette event.',
                FI: 'Valitettavasti tämän tapahtuman arviointi on jo päättynyt.',
                NO: 'Beklager, men det er ikke lenger mulig å evaluere dette eventet.',
                SE: 'Tyvärr är det inte längre möjligt att utvärdera detta event.'
            }
        }
    }
    const getText = () => {
        if (message) return message;
        if (new Date(start) > new Date) return getTranslation(type, 'early');
        return getTranslation(type, 'late');
    }
    return (
        <Backdrop open={true} style={{ color: '#fff', zIndex: 1300, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{__html: getText()}}/>
        </Backdrop>

    )
}
