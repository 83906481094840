import {Box, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import * as React from 'react';
import {MoreInfo} from "./moreInfo";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {PrivacyPolicy} from "./privacyPolicy";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import {Field} from "../Field";
import AddIcon from "@mui/icons-material/Add";
import {green} from "@mui/material/colors";
import {getTranslation} from "../getTranslation";

interface FormType {
    content: TypeContent,
    handleSubmit: any,
    isPreview: boolean,
    displayToolbar: string,
    setDisplayToolbar: any,
    fieldError: any,
    SaveFormValues: any,
    signUp: TypeSignUp,
    isEditor: boolean,
    updateField: any,
    addField: any,
    moveFieldUp: any,
    moveFieldDown: any,
    deleteField: any,
    getField: any,
    showConsent: boolean,
    handleCheckBoxChange: any,
    disableSubmit: any,
    isEdit: boolean,
    colorPreset: any,
    setSignUp: any
}
export const Form = ({isEdit,content, getField, showConsent, disableSubmit, handleCheckBoxChange,handleSubmit, isPreview, displayToolbar, setDisplayToolbar, fieldError, SaveFormValues, signUp, isEditor, updateField, addField, moveFieldUp, moveFieldDown, deleteField, colorPreset, setSignUp}: FormType) => {
    let legalText: string | undefined = content.LegalText ?? content.legalText;
    const legalTextEmpty = !!legalText;
    legalText = !legalText && isPreview ? 'Legal text...' : legalText;
    return (
        <Box component="form" id="form" className="SignupForm" onSubmit={handleSubmit} noValidate>
            {
                content.fields.sort((prev: any, curr: any) => {
                    // Ge objekt utan 'sort'-fält ett stort värde för att de ska hamna sist
                    const prevSort = prev.hasOwnProperty('sort') ? prev.sort : Number.MAX_VALUE;
                    const currSort = curr.hasOwnProperty('sort') ? curr.sort : Number.MAX_VALUE;

                    // Sortera som vanligt
                    return prevSort - currSort;
                }).map( (field, index) => {
                    if ( field.parentField ) {
                        const parentFieldName = content.fields.find( f => f._id === field.parentField)?.name?.toLowerCase();
                        if ( parentFieldName ) {
                            const parentValue = signUp.fields[parentFieldName];
                            if (parentValue) {
                                return (<Field
                                    key={`Question_${index}`}
                                    props={{...field}}
                                    isPreview={isPreview}
                                    displayToolbar={displayToolbar}
                                    setDisplayToolbar={setDisplayToolbar}
                                    fieldError={fieldError}
                                    SaveFormValues={SaveFormValues}
                                    signUp={signUp}
                                    isEditor={isEditor}
                                    updateField={updateField}
                                    moveFieldUp={moveFieldUp}
                                    moveFieldDown={moveFieldDown}
                                    deleteField={deleteField}
                                    setSignUp={setSignUp}
                                />);
                            }
                        }
                        return '';
                    }
                    if ( field.hidden )
                        return;
                    return (<Field
                        key={`Question_${index}`}
                        props={{...field}}
                        isPreview={isPreview}
                        displayToolbar={displayToolbar}
                        setDisplayToolbar={setDisplayToolbar}
                        fieldError={fieldError}
                        SaveFormValues={SaveFormValues}
                        signUp={signUp}
                        isEditor={isEditor}
                        updateField={updateField}
                        moveFieldUp={moveFieldUp}
                        moveFieldDown={moveFieldDown}
                        deleteField={deleteField}
                        setSignUp={setSignUp}
                    />);
                })
            }
            {
                isPreview && content.fields.find(q => q.name === 'Email') && isEditor &&
                <Grid style={{textAlign: 'right'}}>
                    <Tooltip title="Add new field">
                        <IconButton onClick={()=>addField()}>
                            <AddIcon sx={{fontSize: 40, color: green[500]}}/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            {
                 !isEdit &&
                <Typography
                    sx={{
                    border: !legalTextEmpty && isPreview && isEditor ? '1px dotted #808080' : 'none',
                    fontSize: '9pt',
                    color: '#808080',
                    padding: '10px',
                    pt: '5px',
                    cursor: isPreview && isEditor ? 'pointer' : 'unset'
                }} onClick={() => updateField('LegalText')} component="div" variant="caption"
                            dangerouslySetInnerHTML={{__html: legalText}}/>
            }
            { showConsent && !isEdit
                ?
                <div>
                    <MoreInfo handleFieldChange={SaveFormValues} country={signUp.country}></MoreInfo>
                    <FormControlLabel
                        control={<Checkbox required value="true" onChange={SaveFormValues} name="consent" color="primary" />}
                        label={getTranslation('Consent Form', 'consent')}
                        sx={{pt: 2, pb: 2}}
                    />
                </div>

                : ''
            }
            {
                !isEdit &&
                <FormControlLabel
                control={<Checkbox required onChange={handleCheckBoxChange} name="tos" value="tos" color="primary" />}
                label={
                <div>
                <span>{getTranslation('Consent Form', 'privacy_policy')} </span>
                <PrivacyPolicy handleFieldChange={SaveFormValues} country={signUp.country}></PrivacyPolicy>
                </div>
            }
                sx={{pb: 2}}
                />
            }

            <Grid container>
                <Grid item xs={12} sx={{textAlign: 'center', pb: '40px', pt: '40px'}}
                      onMouseOver={()=>{
                          if (isPreview && isEditor)
                              setDisplayToolbar('Submit')
                      }}
                >
                    <Button
                        type="submit"
                        disabled={disableSubmit && !isEdit}
                        variant="contained"
                        sx={{ mt: 3, mb: 2,  marginTop: '10px', backgroundColor: colorPreset?.Button?.backgroundColor??'#D51317', borderRadius: '30px',
                            fontSize: '15px', lineHeight: '18px', fontWeight: 700, pt: '15px', pb: '15px', pl: '40px', pr: '40px' }}
                    >
                        {content.submitLabel??'' !== '' ? content.submitLabel: content.Submit??'Submit'}
                    </Button>
                    {
                        isPreview  && isEditor && content.fields.find(q => q.name === 'Email') && displayToolbar === 'Submit' &&

                        <Tooltip title="Edit">
                            <IconButton onClick={()=>updateField('Submit')}>
                                <EditIcon sx={{fontSize: 20}}/>
                            </IconButton>
                        </Tooltip>

                    }
                </Grid>
            </Grid>
        </Box>
    )
}
