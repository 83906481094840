import './polyfill';
import 'formdata-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react'
import {SignUp} from "./component/signup/"
import {Survey} from "./component/survey/"
import {Email} from "./component/email/"
import { render } from 'react-dom'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {ErrorPage} from "./error";
import {
    GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import {PrintInvitation} from "./component/print/invitation";
import {PrintSurvey} from "./component/print/survey";
render(
    <React.Fragment>
        {
            window.location.pathname.includes('/print/') &&
            <Router basename='/'>
                <Route path="/print/invitation/:uuid">
                    <PrintInvitation isApproval={true}/>
                </Route>
                <Route path="/print/survey/:uuid">
                    <PrintSurvey/>
                </Route>
            </Router>
        }
        {
            !window.location.pathname.includes('/print/') &&
            <GoogleReCaptchaProvider reCaptchaKey="6LcDmcwcAAAAAOamxyeOJ9QM5yTaiIoBMPt8dFv0">
                <Router basename='/'>
                    <Switch>
                            <Route path="/email/:activityId/:templateId/:uuid">
                                <Email/>
                            </Route>
                            <Route path="/email/:activityId">
                                <Email/>
                            </Route>
                            <Route path="/survey/approval/:uuid">
                                <Survey isApproval={true}/>
                            </Route>
                            <Route path="/register/approval/:uuid">
                                <SignUp isApproval={true}/>
                            </Route>
                            <Route path="/survey/:uuid">
                                <Survey/>
                            </Route>
                            <Route path="/register/edit/:uuid">
                                <SignUp isEdit={true}/>
                            </Route>
                            <Route path="/register/:uuid">
                                <SignUp/>
                            </Route>
                            <Route path="/">
                                <ErrorPage/>
                            </Route>
                    </Switch>
                </Router>
            </GoogleReCaptchaProvider>
        }
    </React.Fragment>,
    document.getElementById('root')
)
