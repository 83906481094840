import {Box, Grid} from "@mui/material";
import * as React from 'react';
import {useEffect} from "react";
export const Footer = ({AboutTillotts, Contact, smallLogo,smallLogoImages, jointEvent, html}) => {
    const Logos = () => {
        return (
            <Grid container width={'100%'}>
                {
                    <Grid paddingTop={'10px'} width={'100%'}>
                        <img style={{width: !jointEvent ? 'unset' : '100%'}}  src={`${process.env.REACT_APP_UPLOAD_BUCKET}${smallLogoImages[0].image}`}/>
                    </Grid>
                }
            </Grid>
        )
    }
    return (
        <React.Fragment>
            <Grid className="Footer" container  sx={{ pt: '40px', pb: '20px',  backgroundColor: '#FFFFFF'}}>
                {
                    smallLogoImages?.length && jointEvent ? <Logos/> : ''
                }
                {
                    html && <Grid
                        className="AboutTillots"
                        item xs={12} sx={{pt: '15px', pb: '15px', color: '#3C3D3F', fontSize: '18px', fontWeight: 400}}
                        dangerouslySetInnerHTML={{__html: html}}/>
                }
                {
                    !html && <React.Fragment>
                        <Grid
                            className="AboutTillots"
                            item xs={12} sx={{pt: '15px', pb: '15px', color: '#3C3D3F', fontSize: '18px', fontWeight: 400}}
                            dangerouslySetInnerHTML={{__html: AboutTillotts}}/>
                        {
                            !jointEvent && smallLogoImages?.length ? <Logos/> : ''
                        }
                        {
                            !jointEvent && !smallLogoImages?.length ?
                                <Grid className="SmallLogo">
                                    <img style={{width: '145px'}} className="SmallLogoImage" src={smallLogo}/>
                                </Grid>
                                : ''
                        }
                        <Grid
                            className="AboutTillots"
                            item
                            xs={12}
                            sx={{pt: '15px', pb: '15px', color: '#3C3D3F', fontSize: '18px', fontWeight: 400}}
                            dangerouslySetInnerHTML={{__html: Contact}}
                        />

                    </React.Fragment>
                }

            </Grid>
        </React.Fragment>
    )
}
