import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {MeetingType} from "./MeetingType";
import {Agenda} from "./Agenda";
import {Speakers} from "./Speakers";
import {QR} from "./QR";

export const Body = ({content, colorPreset, uuid, updateField, isEditor, isPreview, displayAgenda}: {content: TypePrintContent, colorPreset: any, uuid: string, updateField: any, isEditor: boolean, isPreview: boolean, displayAgenda: boolean}) => {
    return (
        <Grid container paddingTop={'20px'} height={'calc(200mm + 20px)'} minHeight={'calc(200mm + 20px)'} maxHeight={'calc(200mm + 20px)'} sx={{overflow: 'hidden'}}>
            <Grid item xs={displayAgenda ? 6 : 12}  maxHeight={!displayAgenda ? '174mm' : ''} overflow={!displayAgenda ? 'hidden' : ''}>
                <MeetingType colorPreset={colorPreset} TypeOfEvent={content['TypeOfEvent']} DateTime={content['DateTime']} updateField={updateField} isEditor={isEditor} isPreview={isPreview} />
                <Box onClick={() => updateField('Welcome Text')} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset',pt: '20px'}} dangerouslySetInnerHTML={{__html: content['Welcome Text']}}/>
                <Speakers content={content}/>
                <Box
                    onClick={() => updateField('LegalText')}
                    dangerouslySetInnerHTML={{__html: content['LegalText']??''}}
                    sx={{
                        cursor: isPreview && isEditor ? 'pointer' : 'unset',
                        pt: '10px',
                        fontSize: '7pt',
                    }}
                />
            </Grid>
            {
                displayAgenda ?
                    <Grid item xs={6} display="flex" flexDirection="column" height="100%">
                            <Agenda colorPreset={colorPreset} content={content} updateField={updateField} isEditor={isEditor} isPreview={isPreview} />
                        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
                            <QR uuid={uuid} content={content} updateField={updateField} isEditor={isEditor} isPreview={isPreview} />
                        </Box>
                    </Grid>
                    :
                    <Grid item xs={12}  display="flex" flexDirection="column" justifyContent="flex-end">
                        <QR uuid={uuid} content={content} updateField={updateField} isEditor={isEditor} isPreview={isPreview}/>
                    </Grid>
            }
        </Grid>

)
}