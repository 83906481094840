import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Checkbox, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import StarWhite from '../../assets/StarWhite.png'
import StarBlue from '../../assets/StarBlue.png'
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
export const Question = (props: TypeSurveyContentQuestion) => {
    const [value, setValue] = React.useState<number | null>(0);
    const [notApplicable, setNotApplicable] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState(-1);
    const labels: { [index: string]: string } = {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
    };

    const handleChange = (event: any) => {
        if (event.target.checked)
            setNotApplicable(true);
        else
            setNotApplicable(false);
    }
    return (
        <Box key={props._id} sx={{paddingBottom: '20px', paddingTop: '20px'}}>
            <Typography  component="legend"  sx={{fontSize: '18px', paddingRight: '20px', color: 'black!important', fontWeight: 700}} dangerouslySetInnerHTML={{__html: props.label}}></Typography>
            {
                props.useYesNo ?
                    <Box
                        className="YesNo"
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <FormControl component="fieldset" sx={{backgroundColor: '#FFFFFF',
                            width: '100%', mb:'10px!important'}}>
                            <RadioGroup
                                name={`yesNo_${props._id}`}
                            >
                                <FormControlLabel value='true' control={<Radio />} label={props.labelYes??'Yes'} />
                                <FormControlLabel value='false' control={<Radio />} label={props.labelNo??'No'} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    : ''
            }
            {
                props.useCombo &&
                <FormControl variant="standard" fullWidth sx={{backgroundColor: '#FFFFFF',
                    width: '100%', mb:'10px!important'}}>

                    <Select
                        name={`combo_${props._id}`}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label=""
                    >
                        {
                            props?.choices?.map( choice => <MenuItem value={choice}>{choice}</MenuItem>)
                        }

                    </Select>
                </FormControl>
            }
            {
                props.useRadio &&
                <FormControl >
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name={`radio_${props._id}`}

                    >
                        {
                            props?.choices?.map( choice => <FormControlLabel value={choice} control={<Radio checkedIcon={<RadioButtonCheckedIcon sx={{
                                color: "#000000",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "50%" }}/>} icon={<CircleIcon sx={{
                                color: "#FFFFFF",
                                backgroundColor: "#000000",
                                borderRadius: "50%" }} />}  />} label={choice}/>)
                        }
                    </RadioGroup>
                </FormControl>
            }
            {
                props.useRating ?
                    <Box
                        className="Rating"
                        sx={{
                            display: 'flex',
                            mr: 'auto',
                            ml: 'auto'
                        }}
                    >
                        <FormControl >
                            <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                row
                                name={`rating_${props._id}`}
                                onChange={(event, newValue) => setValue(parseInt(newValue))}
                            >
                                {
                                    ['N/A',0,1,2,3,4,5,6,7,8,9,10].map( choice =>
                                        <FormControlLabel
                                            labelPlacement="bottom"
                                            value={choice}
                                            className="RatingLabel"
                                            control={<Radio className="RatingRadio" checkedIcon={<RadioButtonCheckedIcon sx={{
                                                color: "#000000",
                                                p:0,
                                                backgroundColor: "#FFFFFF",
                                                borderRadius: "50%" }}/>}
                                            icon={<CircleIcon sx={{
                                                color: "#FFFFFF",
                                                backgroundColor: "#000000",
                                                borderRadius: "50%" }} />}  />
                                            }
                                            sx={{ml: '2px', mr: '2px'}}
                                            label={choice}/>)
                                }
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    : ''
            }

            {
                (value && props.useComment) || (!props.useRating && props.useComment) ? <TextField
                    margin="normal"
                    sx={{backgroundColor: '#FFFFFF',
                        width: '100%', mb:'10px!important'}}
                    fullWidth
                    multiline
                    minRows={3}
                    label=""
                    name={`comment_${props._id}`}
                /> : ''
            }
        </Box>
    )
}
