import {Backdrop, CircularProgress, Grid, Paper} from "@mui/material";
import {Header} from "./Header";
import * as React from "react";
import {useEffect} from "react";
import axios from "axios";
import {sendErrorMessage} from "../../Error";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";

export const PrintSurvey = () => {
    const params = useParams();
    // @ts-ignore
    const uuid = React.useRef(params.uuid)
    const [content, setContent] = React.useState<TypePrintContent | null>();
    const [isPreview, setIsPreview] = React.useState(false);
    const [isEditor, setIsEditor] = React.useState(false);
    const [colorPreset, setColorPreset] = React.useState<any>();
    const [smallLogoImages, setSmallLogoImages] = React.useState(undefined);
    const [jointEvent, setJointEvent] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(true);
    const [activityId, setActivityId] = React.useState(uuid.current);
    const updateField = (fieldName: string) => {
        console.log({fieldName}, isEditor, isPreview);
        if (isPreview && isEditor) {
            let fieldType = fieldName === 'Button' ? 'Text' : 'HTML';
            console.log({type: 'updateTemplateField', fieldName, fieldType });
            window.parent.postMessage({type: 'updateTemplateField', fieldName, fieldType }, "*");
        }
    }
    useEffect( () => {
        if ( uuid.current !== "PREVIEW" ) {
            (async() => {
                await getPrintVersion();
            })();
        } else {
            window.addEventListener("message", (event) => {
                let tmpContent: TypePrintContent = {};
                if ( event.data.type === "updatePreview" ) {
                    setSmallLogoImages(event.data?.smallLogoImages);
                    setColorPreset(event.data?.colorPreset)
                    setIsPreview(true);
                    setIsEditor(event.data.isEditor)
                    setActivityId(event.data?.activityId);
                    setJointEvent(event.data?.jointEvent);
                    for (let field of event.data.fields) tmpContent = {...tmpContent, [field.name]: field.value};

                    setContent(tmpContent);
                    setLoading(false);
                }
            }, false);
        }
    }, [])


    const getPrintVersion = async () => {
        try {
            setLoading(true);
            const res = await axios.get(`${process.env.REACT_APP_PRINT_API}survey/${uuid.current}`);
            let tmpContent: TypePrintContent = {};
            for (let field of res.data.template.fields) tmpContent = {...tmpContent, [field.name]: field.value};

            tmpContent = {
                ...tmpContent,
                country: res.data.country,
                Speakers: res.data.Speakers,
            }
            setColorPreset(res.data?.colorPreset)
            setJointEvent(res.data?.jointEvent);
            setSmallLogoImages(res.data?.smallLogoImages);
            setLoading(false);
            setContent(tmpContent);
            setIsPreview(false);
        } catch ( e: any ) {
            setLoading(false);
            await sendErrorMessage(e,  'public/print/getPrint', "Cannot Get Print");
        }
    }
    return (
        <Box display="flex" alignItems="center" justifyContent="center" >
            <Paper
                className={'PrintPaper'}
                sx={{
                    maxWidth: 'calc(210mm - 60px)',
                    maxHeight: 'calc(297mm - 60px)',
                    minWidth: 'calc(210mm - 60px)',
                    minHeight: 'calc(112mm)',
                    padding: '40px',
                    fontSize: '10pt',
                    position: 'relative', // Nödvändig för att positionera pseudo-elementet
                    '&::after': { // Lägger till ett pseudo-element
                        content: '""', // Nödvändig för att visa pseudo-elementet
                        position: 'absolute',
                        bottom: 0, // Positionerar vid botten av Paper-komponenten
                        left: 0,
                        right: 0,
                        height: '40px', // Höjd på det blåa området
                        backgroundColor: `${colorPreset?.Header.EventLocation?.backgroundColor??'#00A6DD'}`, // Färgen på det blåa området
                    },
                }}
            >
                <Header
                    Header={content?.Header}
                    SubHeader={content?.SubHeader}
                    logoImage={content?.__LogoImage}
                    isEditor={isEditor}
                    isPreview={isPreview}
                    colorPreset={colorPreset}
                    smallLogoImages={smallLogoImages}
                    updateField={updateField}
                    jointEvent={jointEvent}
                    uuid={activityId}
                />
            </Paper>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}