import { Grid } from "@mui/material";
import * as React from "react";
import Button from "@mui/material/Button";
import {getTranslation} from "./getTranslation";

export const Confirmation = ({ content, colorPreset, icsEnabled = false, country, uuid }: {
    content: string;
    colorPreset?: any;
    icsEnabled?: boolean;
    country?: string;
    uuid?: string;
}) => {
    return (
        <React.Fragment>
            <Grid
                className="ConfirmationWrapper"
                container
                sx={{
                    pl: "40px",
                    pr: "40px",
                    pt: "20px",
                    pb: "20px",
                    width: "100%",
                    backgroundColor: "rgba(60, 61, 63, 0.08)",
                }}
            >
                <Grid
                    className="Confirmation"
                    item
                    xs={12}
                    sx={{
                        pt: "15px",
                        pb: "15px",
                        color: "#3C3D3F",
                        fontSize: "18px",
                        fontWeight: 700,
                    }}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {
                    icsEnabled && (
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                        <a
                            href={`${process.env.REACT_APP_CALENDAR_ENDPOINT}${uuid}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{textDecoration: "none"}}
                        >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        marginTop: "10px",
                                        backgroundColor: colorPreset?.Button?.backgroundColor ?? "#D51317",
                                        borderRadius: "30px",
                                        fontSize: "15px",
                                        lineHeight: "18px",
                                        fontWeight: 700,
                                        pt: "15px",
                                        pb: "15px",
                                        pl: "40px",
                                        pr: "40px",
                                    }}
                                >
                                    {getTranslation('Registration Form', 'label_addtocalendar')}
                                </Button>
                        </a>
                    </Grid>

                    )}
            </Grid>
        </React.Fragment>
);
};