import {Box, Grid} from "@mui/material";
import * as React from 'react';
import QRCode from "react-qr-code";
export const Header = ({Header, SubHeader, logoImage, isEditor, isPreview, updateField, colorPreset, smallLogoImages, jointEvent, uuid}) => {
    const [showRightLogo, setShowRightLogo] = React.useState((smallLogoImages?.length??0) < 3 && !jointEvent)
    const url = `${window.location.origin}/survey/${uuid}`;
    React.useEffect( () => {
        setShowRightLogo((smallLogoImages?.length??0) < 3 && !jointEvent);
    }, [smallLogoImages])
    return (
        <React.Fragment>
            <Grid container  className="Header" height={200} paddingLeft={0}>
                <Grid item xs={ showRightLogo ? 9: 12}>
                {
                    smallLogoImages?.length === 1 &&
                        <Grid paddingTop={'10px'} paddingRight={'40px'}>
                            <img style={{width: !jointEvent ? 'unset' : '100%'}}  src={`${process.env.REACT_APP_UPLOAD_BUCKET}${smallLogoImages[0].image}`}/>
                        </Grid>
                }
                {
                    smallLogoImages?.length > 1 &&

                    <Grid container width={'100%'}>
                        {
                            smallLogoImages.map( (image: any, index: number) => {
                                return (
                                    <Grid xs={smallLogoImages.length >2 ? 4 : 6} direction="column" sx={{display: 'grid'}}
                                          alignItems="center" >
                                        <Box
                                            display="flex"
                                            justifyContent={index === 3 ? 'left' : 'center'}
                                            alignItems="center"
                                            height="100%"
                                            padding={'10px'}
                                        >
                                            <img style={{width: '100%'}} className="SmallLogoImage" src={`${process.env.REACT_APP_UPLOAD_BUCKET}${image.image}`}/>
                                        </Box>
                                    </Grid>)
                            })
                        }
                    </Grid>
                }
                    <Grid container>
                        <Grid item xs={12} pb={'30px'} pt={'30px'}>
                            <Box onClick={() => updateField('SubHeader')} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset', fontSize: '19px', lineHeight: '24px', pt: '10px', color:`${colorPreset?.Header?.SubSubject?.color??'#3C3D3F'}!important`}} dangerouslySetInnerHTML={{__html: SubHeader}}/>
                        </Grid>
                        <Grid item xs={4}>
                            <QRCode size={150} value={url} />
                        </Grid>
                        <Grid item xs={8} >
                            <Box onClick={() => updateField('Header')} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset', color:`${colorPreset?.Header?.Subject?.color??'#D3031C'}!important`, fontSize: '31px', pt: '40px', verticalAlign: 'bottom'}} dangerouslySetInnerHTML={{__html: Header}} />
                        </Grid>

                    </Grid>
                </Grid>
                {
                    showRightLogo &&
                    <Grid item xs={3} sx={{textAlign: 'right', height: '100%'}} alignContent="right">
                        <img style={{height: '100%'}} className="LogoImage"  src={colorPreset?.Header?.Image?.Desktop?.src??logoImage}/>
                    </Grid>
                }
            </Grid>

        </React.Fragment>
)
}
