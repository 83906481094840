import * as React from 'react';
import axios from "axios";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {sendErrorMessage} from "../Error";
import Typography from "@mui/material/Typography";
import logo from '../../assets/logo.png'

export const Email = () => {
    const params = useParams();
    // @ts-ignore
    const {uuid, activityId, templateId} = params;
    const [email, setEmail] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState(false);
    const getEmail = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_EMAIL_API}${activityId}/${templateId}/${uuid}`);
            setEmail(res.data);
        } catch ( e: any ) {
            await sendErrorMessage(e,  'public/email/Email', "Cannot Get Email");
            setErrorMessage(true);
        }
    }

    useEffect( () => {
        try {
            if ( uuid.current !== "PREVIEW" ) {
                (async() => {
                    await getEmail();
                })();
            }
        } catch ( e: any ) {
            (async() => {
                await sendErrorMessage(e,  'public/email/Email', "Cannot Get Email");
            })();
            setErrorMessage(true);
        }
    }, [])
    const ErrorMessage = () => {
        return (
            <div style={{marginTop: 'auto', marginBottom: 'auto', width: '100%', maxWidth: '700px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
                <img alt="Tillotts Logo" src={logo}/>
                <Typography component="div" variant="h4" sx={{marginTop: '20px', textAlign: 'center'}}>
                    Uh uh, we are really sorry!
                </Typography>
                <Typography component="h1" variant="h5" sx={{marginTop: '30px', paddingRight: '20px', paddingLeft: '40px', width: '100%', textAlign: 'left'}}>
                    An unexpected error has occurred and we could sadly not display the email. <br/>As an alternative, please send an e-mail to <a href = "mailto: rose-marie.blom@tillotts.com">rose-marie.blom@tillotts.com</a> and ask her to send you the invitation.
                </Typography>
            </div>
        )
    }
    return (
        <div>
        { errorMessage ? <ErrorMessage/> :
        <iframe style={{height: '2800px', width: '100%', overflow: 'auto'}} frameBorder={0} srcDoc={email} id="templatePreview"></iframe>
        }
        </div>
    );
}
