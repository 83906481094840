import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import '../../styling.css';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
    Backdrop, Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel, MenuItem,
    Paper, Radio, RadioGroup, Select
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import StarIcon from '@mui/icons-material/Star';
import axios from "axios";
import {sendErrorMessage} from "../Error";
import logo from "../../assets/logo.png";
import FavoriteBorder from '@mui/icons-material/StarBorder';
import Favorite from '@mui/icons-material/Star';
import {Header} from "../Header";
import {Details} from "../Details";
import {Form} from "./Form";
import {Footer} from "../Footer";
import {Confirmation} from "../Confirmation";
import {WebFormNotOpen} from "../signup/WebFormNotOpen";
import {setTranslations} from "../getTranslation";
const theme = createTheme();
const emptyObj: TypeSurveyContent = {
    Header: '',
    Header2: '',
    DateTime:'',
    Header3:'',
    Header4: '',
    Button: '',
    QuestionLabel:'',
    QuestionYesLabel:'',
    QuestionNoLabel:'',
    __approvalCode: '',
    __LogoImage: '',
    questions: [],
    TopHeader: '',
    __FooterImage: logo,
    ConfirmationThankYouText: 'Tack för dina svar',
    ConfirmationText: 'Du kan nu stänga det här fönstret!',
    isOpen: true,
    start: new Date,
    end: new Date,
    country: 'SE',
    Footer: '',
}

interface SurveyInput {
    isApproval?: boolean
}
export const Survey = ({isApproval}: SurveyInput) => {
    const params = useParams();
    // @ts-ignore
    const uuid = React.useRef(params.uuid)
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [content, setContent] = React.useState<TypeSurveyContent>(emptyObj);
    const [isPreview, setIsPreview] = React.useState(false);
    const [isEditor, setIsEditor] = React.useState(false);
    const [linkSent, setLinkSent] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [colorPreset, setColorPreset] = React.useState(undefined);
    const [smallLogoImages, setSmallLogoImages] = React.useState(undefined);
    const [jointEvent, setJointEvent] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState('SE');
    const [footer, setFooter] = React.useState('');
    useEffect( () => {
        if ( uuid.current !== "PREVIEW" ) {
            (async() => {
                await getSurvey();
            })();
        } else {
            window.addEventListener("message", async (event) => {
                let tmpContent: TypeSurveyContent = content;
                if ( event.data.type === "updatePreview" ) {
                    const res = await axios.get(`${process.env.REACT_APP_SURVEY_API}${event.data.activityId}`);
                    setSmallLogoImages(event.data?.smallLogoImages);
                    setColorPreset(event.data?.colorPreset)
                    setIsPreview(true);
                    setIsEditor(event.data.isEditor)
                    setJointEvent(event.data?.jointEvent);
                    setFooter(event.data?.Footer)
                    for (let field of event.data.fields) {
                        field.value = field.name === "__Questions" ? field.questions : field.value;
                        field.name = field.name === "__Questions" ? "questions" : field.name;
                        tmpContent = {...tmpContent, [field.name]: field.value};
                    }
                    setTranslations(res.data?.translations);
                    setContent(tmpContent);
                    setLoading(false);
                }
            }, false);
        }
    }, [])


    const getSurvey = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SURVEY_API}${uuid.current}`);
            let tmpContent: TypeSurveyContent = content;
            for (let field of res.data.template.fields) {
                if ( field.name === "__Questions" ) {
                    field.name = field.name === "__Questions" ? "questions" : field.name;
                    field.value = field.questions;
                }
                tmpContent = {...tmpContent, [field.name]: field.value};
            }
            tmpContent = {
                ...tmpContent,
                country: res.data.country,
                isOpen: !isApproval ? res.data.isOpen : isApproval,
                start: res.data.start,
                end: res.data.end
            }
            setColorPreset(res.data?.colorPreset)
            setJointEvent(res.data?.jointEvent);
            setTranslations(res.data?.translations);
            setCountry(tmpContent.country);
            setSmallLogoImages(res.data?.smallLogoImages);
            setLoading(false);
            setFooter(res.data?.Footer)
            setContent(tmpContent);
        } catch ( e: any ) {
            setLoading(false);
            setErrorMessage(true);
            await sendErrorMessage(e,  'public/survey/getSurvey', "Cannot Get Survey");
        }
    }
    // @ts-ignore
    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            let result = {};
            let _ids: string[] = [];
            // @ts-ignore
            for(const pair of data.entries()) {
                const _id = pair[0].split("_")[1];
                _ids.push(_id);
                const field = pair[0].split("_")[0];
                let value = pair[1];
                if ( field === 'yesNo') {
                    value = value === 'true';
                }
                if (!result.hasOwnProperty(_id)) {
                    console.log(_id,field, value);
                    const question = content.questions.find( (q: TypeSurveyContentQuestion) => q._id === _id);
                    // @ts-ignore
                    result[_id] = {label: question.label, category: question.category}
                }
                result = {...result, [_id]: {...result[_id], [field]: value}}
            }
            let missedQuestions: string = '';
            for ( const question of content.questions ) {
                if (question._id && (_ids.indexOf(question._id) > -1 || (!question.useRating && !question.useYesNo && !question.useCombo && !question.useRadio))) {
                    console.log(question.label, 'is filled out');
                } else {
                    missedQuestions +=`\n${question.category} - ${question.label}`;
                    console.log(question.label, 'is not filled out');
                }
            }
            if ( missedQuestions ) {
                alert('Kindly answer all questions, you forgot  ' + missedQuestions);
            } else {

                if (!isPreview && !isApproval) {
                    setLoading(true);
                    const reCaptcha = await handleReCaptchaVerify();
                    await axios.put(`${process.env.REACT_APP_SURVEY_API}`, {
                        payload: {
                            _id: uuid.current,
                            survey: result
                        },
                        action: 'SUBMIT_SURVEY',
                        reCaptcha
                    });
                }
                setLinkSent(true);
                setLoading(false);


            }

        } catch ( e: any ) {
            setLinkSent(true);
            setLoading(false);
            await sendErrorMessage(e,  'public/survey/handleSubmit', "Cannot submit survey");
        }


    };
    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('survey');
        return token;
        // Do whatever you want with the token
    };
    const Question = ( props: TypeSurveyContentQuestion ) =>{
        const [value, setValue] = React.useState<number | null>(0);
        const [notApplicable, setNotApplicable] = React.useState<boolean>(false);
        const [hover, setHover] = React.useState(-1);
        const labels: { [index: string]: string } = {
            1: '',
            2: '',
            3: '',
            4: '',
            5: '',
            6: '',
            7: '',
            8: '',
            9: '',
            10: '',
        };
        /*
<Autocomplete
    id={`comment_${props._id}`}
    renderInput={(params) => (
      <TextField
          {...params}
          variant="standard"
          label=""
          placeholder=""
      />
     )}
    options={props.choices??[]}
    />
</FormControl>
*/
        const handleChange = (event: any) => {
            if (event.target.checked)
                setNotApplicable(true);
            else
                setNotApplicable(false);
        }
            return (
                <Box key={props._id} sx={{marginTop: '20px'}}>
                    <Typography  component="legend"  sx={{fontSize: '15pt', paddingRight: '20px', color: 'black!important'}} dangerouslySetInnerHTML={{__html: props.label}}></Typography>
                    {
                        props.useYesNo ?
                            <Box
                                className="YesNo"
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                <FormControl component="fieldset" >
                                    <RadioGroup
                                        name={`yesNo_${props._id}`}
                                    >
                                        <FormControlLabel value='true' control={<Radio />} label={props.labelYes??'Yes'} />
                                        <FormControlLabel value='false' control={<Radio />} label={props.labelNo??'No'} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            : ''
                    }
                    {
                        props.useCombo &&
                        <FormControl variant="standard" fullWidth>

                            <Select
                                name={`combo_${props._id}`}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label=""
                            >
                                {
                                    props?.choices?.map( choice => <MenuItem value={choice}>{choice}</MenuItem>)
                                }

                            </Select>
                        </FormControl>
                    }
                    {
                        props.useRadio &&
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name={`radio_${props._id}`}
                            >
                                {
                                    props?.choices?.map( choice => <FormControlLabel value={choice} control={<Radio />} label={choice}/>)
                                }
                            </RadioGroup>
                        </FormControl>
                    }
                    {
                        props.useRating ?
                            <Box
                                className="Rating"
                                sx={{
                                    display: 'flex'
                                }}
                            >
                                { !notApplicable ?
                                <Rating
                                    name={`rating_${props._id}`}
                                    sx={{pr: '4px'}}
                                    max={10}
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                /> : ''
                                }
                                {!value ? <FormControlLabel control={<Checkbox
                                    name={`notApplicable_${props._id}`}
                                    onChange={handleChange}
                                    icon={<FavoriteBorder />} checkedIcon={<Favorite />}
                                    sx={{
                                        pt: '0px', pr: '5px',
                                        color: 'black',
                                        '&.Mui-checked': {
                                            color: 'black',
                                        },
                                    }}  />} label="N/A" /> : ''}
                                {value !== null && (
                                    <Box sx={{ ml: 2}}>{labels[hover !== -1 ? hover : value]}</Box>
                                )}
                            </Box>
                            : ''
                    }

                    {
                        (value && props.useComment) || (!props.useRating && props.useComment) ? <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            minRows={3}
                            label=""
                            name={`comment_${props._id}`}
                        /> : ''
                    }
                </Box>
            )
    }
    const updateField = (fieldName: string) => {
        if (isPreview && isEditor) {
            let fieldType = fieldName === 'Button' ? 'Text' : 'HTML';
            window.parent.postMessage({type: 'updateTemplateField', fieldName, fieldType }, "*");
        }
    }
    return (
        <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md" sx={{marginTop: 'auto', marginBottom: 'auto', width: '100%', maxWidth: '700px!important'}}>
                    <Paper elevation={3}>
                    <CssBaseline />
                    <Box
                        className="PaperBox"
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Header
                            Header={content.Header}
                            smallLogo={content.__FooterImage}
                            SubHeader={content.SubHeader}
                            TypeOfEvent={content.TypeOfEvent}
                            logoImage={content.__LogoImage}
                            DateTime={content.DateTime}
                            isEditor={isPreview}
                            isPreview={isEditor}
                            colorPreset={colorPreset}
                            smallLogoImages={smallLogoImages}
                            updateField={updateField}
                            jointEvent={jointEvent}
                        />

                        {
                            !content.isOpen ?
                                <WebFormNotOpen country={country} end={content.end} start={content.start} type={'Survey Form'}/> :
                                <>
                        {errorMessage ? <img style={{marginTop: '20px'}} alt="Tillotts Logo" src={logo}/> : ''}

                        { errorMessage ? <Typography component="div" variant="h4" sx={{marginTop: '20px'}}>
                            Uh uh, we are really sorry!
                        </Typography> : '' }
                        { errorMessage ? <Typography component="h1" variant="h5" sx={{marginTop: '30px', paddingRight: '20px', paddingLeft: '40px', width: '100%'}}>
                            An unexpected error has occurred so the survey cannot be displayed. <br/>As an alternative, please send an e-mail to <a href = "mailto: rose-marie.blom@tillotts.com">rose-marie.blom@tillotts.com</a>
                        </Typography> : '' }
                        {
                            linkSent && !errorMessage &&
                            <Box onClick={() => updateField('ConfirmationText')} sx={{
                                cursor: isPreview && isEditor ? 'pointer' : 'unset',
                                width: '100%'
                            }}> <Confirmation content={content.ConfirmationText} colorPreset={colorPreset} uuid={uuid?.current} /></Box>
                        }
                        {
                            !linkSent &&
                                <>
                                    <Details
                                        content={content.Header4}
                                        isEditor={isEditor}
                                        isPreview={isPreview}
                                        updateField={() => updateField('Header4')}
                                    />
                                    <Form
                                        colorPreset={colorPreset}
                                        content={content}
                                        handleSubmit={handleSubmit}
                                        isEditor={isEditor}
                                        isPreview={isPreview}
                                        updateField={updateField}
                                    />
                                    <Footer
                                        smallLogo={content.__FooterImage}
                                        AboutTillotts={content.AboutTillotts}
                                        Contact={content.Contact}
                                        smallLogoImages={smallLogoImages}
                                        jointEvent={jointEvent}
                                        html={footer}
                                    />
                                </>

                        }
                        <span style={{color: 'gray', fontSize: '9pt', marginBottom: '20px', marginTop: '20px'}}>{content.__approvalCode}</span>
                                </>
                        }
                    </Box>
                    </Paper>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Container>
        </ThemeProvider>
    );
}
